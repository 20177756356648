<template>
  <div class="p10 bsbb">
    <van-nav-bar left-arrow @click-left="onClickLeft" class="fz30">
      <template #title>
        <div class="top-nav df aic jcsb">
          <div class="df aic jcc">
            <span style="font-weight: bold; font-size: 20px; line-height: normal">充值</span>
          </div>
        </div>
      </template>
    </van-nav-bar>
    <div class="detail">
      <div class="info-box p20 bsbb">
        <van-field v-model="money" label="充值金额" label-width="120" placeholder="充值金额"
          :rules="[{ required: true, message: '请输入充值金额' }]" />
        <div class="info-li" @click="showPay = true">
          <div class="li-l">支付方式</div>
          <div class="li-r">{{ dataPay[checkPay].label }}&nbsp;></div>
        </div>
      </div>
      <div class="padded-15 text-danger fz28">
        <!--   温馨提示：充值金额最低299元。 -->
        <div class="btn padded-t-15">
          <van-button type="success" block @click="onSubmit()">
            确认购买
          </van-button>
          <!-- <van-button type="warning" block @click="goTo('/user/recharge')">
            转账汇款
          </van-button> -->
        </div>
      </div>
    </div>
    <van-dialog v-model:show="showPay" title="选择支付方式" @confirm="showPay = false">
      <van-radio-group v-model="checkPay">
        <van-radio v-for="(item, index) of dataPay" :key="item.value" class="pay" shape="square" :name="index">{{
          item.label }}</van-radio>
      </van-radio-group>
    </van-dialog>
  </div>
</template>

<script>
import { checkPayPwdIsCorrect, recharge2 } from "@/utils/api.js";
export default {
  name: "info",
  data() {
    return {
      title: "充值",
      step: 1,
      money: "",
      min: 1,
      data: {},
      payPassword: "",
      errorInfo: "",
      showKeyboard: true,
      passwordShow: false,
      showPay: false,
      checkPay: 0,
      day: 0,
      dataPay: [
        { label: "支付宝", value: "alipay_wap" },
       // { label: "微信", value: "weixin" },
        // { label: '银行卡', value: 'bankcard' },
        // { label: '云闪付', value: 'ysf' }
      ],
    };
  },
  created() { },
  computed: {},
  methods: {
    confirmPassword() {
      this.passwordShow = true;
    },
    // 检测支付密码是否正确
    checkPayPwdIsCorrect() {
      const param = { payPassword: this.payPassword };
      checkPayPwdIsCorrect(param).then((res) => {
        if (res.code === 200) {
          if (res.data === "true") {
            this.onSubmit();
          } else {
            this.payPassword = "";
            this.$toast(res.message);
          }
        } else {
          this.$toast(res.message);
        }
      });
    },
    goTo(to, params) {
      this.$router.push({ path: to, query: params });
    },
    onSubmit() {
      if (this.productType === "3") {
        this.totalFenHong = 0;
      }
      const param = {
        productId: this.data.id,
        money: this.totalPrice,
        money: this.money,
        fenhong: this.totalFenHong,
        paytype: this.dataPay[this.checkPay].value,
      };
      this.$toast.loading({
        duration: 0,
        message: "提交中...",
        forbidClick: true,
      });
      setTimeout(() => {
        recharge2(param).then((res) => {
          this.$toast.clear();
          if (res.code === 200) {
            window.location.href = res.data;
          } else {
            this.$toast(res.message);
            if (res.message === "请先实名") this.$router.push("/home/realName");
          }
        });
      }, 1700);
    },
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .van-badge__wrapper {
  font-size: 32px !important;
}

::v-deep .van-nav-bar__arrow {
  font-size: 46px !important;
}

::v-deep .van-nav-bar__content {
  height: 70px;
  line-height: 70px;
}

.van-dropdown-menu__bar {
  box-shadow: none !important;
}

.van-nav-bar__content {
  box-shadow: none !important;
}



::v-deep .van-nav-bar__title {
  font-size: 46px;
  color: #000;
}

.detail {
  .title {
    color: #2a2a2a;
    font-size: 16px;
    font-weight: 700;
  }
}

.info-box {
  width: 690px;
  height: 300px;
  background: #fff;
  box-shadow: 0 16px 40px -4px rgb(243 142 142 / 8%);
  border-radius: 20px;
  margin: 20px auto;

  .van-field {
    height: 80px;
  }

  .info-li {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .li-l {
      font-size: 30px;
      color: #999;
    }

    .li-r {
      display: flex;
      font-size: 30px;
      align-items: center;
    }
  }
}

.van-button {
  width: 690px;
  height: 100px;
  border-radius: 20px;
  margin-top: 20px;
}

.pay {
  padding: 10px;

  ::v-deeep .van-radio__icon .van-icon {
    width: 100% !important;
    height: auto !important;
  }
}

::v-deep .van-field__control {
  text-align: right;
}
</style>
<style lang="scss">
.pay {
  padding: 10px;

  .van-radio__icon .van-icon {
    width: 100% !important;
    height: auto !important;
  }

  .van-radio__label {
    line-height: normal;
  }
}
</style>
